import React from "react"
import { Link, graphql } from "gatsby"
import { SimpleImg } from 'react-simple-img';

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import BannerAgenda from '../../components/BannerAgenda'

class BlogIndex extends React.Component {
  	render() {
		const { data } = this.props
		const siteTitle = data.site.siteMetadata.title
		const posts = data.allContentfulPost.edges

		return (
			<Layout location={this.props.location} title={siteTitle}>

				<SEO
				title="Noticias" keywords={[`noticias`, `barcelona eventos`, `bodas`, `xv años`]}
				/>

				<section className="banner-noticias">
					<div className="container">
						<h1 className="title">
							<small>Barcelona Eventos</small>
							<span>Noticias</span>
						</h1>
					</div>
				</section>

				<section className="noticias-index">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-sm-12 col-md-10 col-lg-9">
								{posts.map(({ node }) => {
									const title = node.titulo || node.slug
									return (
										<div key={node.slug} className="item">
											<Link to={`/noticias/${node.slug}`}>
												<div className="thumb">
													<SimpleImg srcSet={node.imagen.fluid.srcSet} placeholder={false} />
												</div>
											</Link>
											<div className="caption">
												<span>
													<h2>
														<Link to={`/noticias/${node.slug}`}>
															{title}
														</Link>
													</h2>
													<p>{node.subtitulo}</p>
												</span>
											</div>
										</div>
									)
								})}
							</div>
						</div>
					</div>
				</section>

				<BannerAgenda paddingtop="pt-7"/>

			</Layout>
		)
	}
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulPost {
      edges {
        node {
          titulo
          subtitulo
		  slug
		  imagen{
			fluid{
			  srcSet
			  ...GatsbyContentfulFluid
			}
		  }
        }
      }
    }
  }
`
